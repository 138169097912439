<template>
	<b-card class="settings">
	<b-row>
		<b-col lg="6">
			<validation-observer #default="{ handleSubmit }" ref="refFormObserver">
			<b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
			<template v-for="setting in settings">
					
					<b-form-group
						v-if="typeof setting.value == 'boolean'"
						:key="setting.key"
					> 
						<b-form-checkbox @input="onInput" :checked="setting.value" name="check-button" switch v-model="settingsAlter[setting.key]"  :key="setting.key">
							{{ dict[setting.key] }}
						</b-form-checkbox>
					</b-form-group>

				<validation-provider
					v-if="typeof setting.value == 'number'"
					:key="setting.key"
					#default="validationContext"
					rules="double"
				>
					<b-form-group> 
						<b-input-group :prepend="dict[setting.key]">
							<b-form-input @input="onInput" type="number" step="0.01" :placeholder="setting.key" :value="setting.value" v-model.number="settingsAlter[setting.key]"
								:key="setting.key"
								trim
								:state="getValidationState(validationContext)"
							/>
						</b-input-group>

						<b-form-invalid-feedback>
							{{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>

				<validation-provider
					v-if="typeof setting.value == 'string'"
					:key="setting.key"
					#default="validationContext"
					rules="alpha-num"
				>
					<b-form-group> 
						<b-input-group :prepend="dict[setting.key]">
							<b-form-input @input="onInput"
								:placeholder="setting.key"
								:value="setting.value"
								v-model="settingsAlter[setting.key]"
								:key="setting.key"
								:state="getValidationState(validationContext)"
							/>
						</b-input-group>
						<b-form-invalid-feedback>
							{{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>
				
				<b-form-group v-if="typeof setting.value == 'object'" :key="setting.key">
					<b-input-group :prepend="dict[setting.key]">
						<validation-provider
							:key="key"
							#default="validationContext"
							rules="integer"
							v-for="(nested, key) in setting.value"
						>
							<b-form-input @input="onInput"
								:placeholder="key"
								:value="nested"
								v-model="settingsAlter[setting.key][key]"
								:state="getValidationState(validationContext)"
							/>
						</validation-provider>
					</b-input-group>
				</b-form-group>
			</template>
			<div class="d-flex mt-1">
				<b-button
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					variant="outline-warning"
					class="mr-4"
					type="submit"
					@click="resetToDefault"
				>
					Заводские настройки
				</b-button>
				<b-button
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					variant="primary"
					class="mr-2"
					type="submit"
					v-if="dirty"
				>
					Сохранить
				</b-button>
				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					@click="resetSettings"
					v-if="dirty"
				>
					Отмена
				</b-button>
			</div>
			</b-form>
			</validation-observer>
		</b-col>
		<b-col lg="3">Версия</b-col>
		<b-col lg="3">Дата последнего обновления</b-col>
	</b-row>
	</b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BCard, BCardText, BRow, BCol, BFormCheckbox, BInputGroup, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BButton } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api';
import { required, alphaNum, email, double, integer } from '@validations'
import axios from 'axios';
import formValidation from '@/@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive'

export default {
	components: {
		BCard,
		BCardText,
		BRow,
		BCol,
		BButton,
		BFormCheckbox, BInputGroup, BFormGroup, BFormInput, BForm, ValidationProvider, ValidationObserver, BFormInvalidFeedback
	},
	data() {
		return {
			dict: {
				allowGrayscale: 'Разрешить чёрно-белые фото',
				allowMultiFace: 'Разрешить больше одного лица',
				allowClosedEyes: 'Разрешить закрытые глаза',
				allowedBlurriness: 'Допустимая размытость',
				allowedFaceAlignment: 'Допустимый поворот лица',
				recognitionThreshold: 'Порог разности лиц',
			},
			required,
			alphaNum,
			email,
			double,
			integer
		}
	},
	computed: {
		console: () => console,
		window: () => window,
	},
	setup(props, { emit, parent }) {
		const dirty = ref(false);
		const settings = ref([]);
		const settingsAlter = ref({});
		const defaultSettings = ref({});
		const initialSettings = ref({});
		parent.$http.get((process.env.VUE_APP_BACKEND || '') + '/api/livenessSettings/standard').then(res => {
			let map = Object.keys(res.data.value).map(key => ({key: key, value: res.data.value[key]}));
			settings.value = map;
			settingsAlter.value = JSON.parse(JSON.stringify(res.data.value))
			initialSettings.value = JSON.parse(JSON.stringify(res.data.value));
		})
		parent.$http.get((process.env.VUE_APP_BACKEND || '') + '/api/livenessSettings/default').then(res => {
			defaultSettings.value = res.data.value;
		})

		const resetSettings = () => {
			Object.assign(settingsAlter, JSON.parse(JSON.stringify(initialSettings)))
			setTimeout(() => dirty.value = false, 1);
		}

		const resetToDefault = () => {
			resetForm();
			Object.assign(settingsAlter, JSON.parse(JSON.stringify(defaultSettings)))
			parent.$http.patch((process.env.VUE_APP_BACKEND || '') + '/api/livenessSettings/standard', {value:JSON.parse(JSON.stringify(defaultSettings.value))})
			dirty.value = false
		}

		const onSubmit = () => {
			parent.$http.patch((process.env.VUE_APP_BACKEND || '') + '/api/livenessSettings/standard', {value:JSON.parse(JSON.stringify(settingsAlter.value))})
			initialSettings.value = JSON.parse(JSON.stringify(settingsAlter.value))
			resetForm();
			dirty.value = false;
		}

		const { refFormObserver, getValidationState, resetForm } = formValidation(resetSettings)

		

		return {
			settings,
			settingsAlter,
			onSubmit,
			resetSettings,
			resetToDefault,

			refFormObserver,
			getValidationState,
			resetForm,

			dirty
		}
	},
	directives: {
		Ripple
	},
	methods: {
		onInput() {
			this.dirty = true;
		}
	}
}
</script>

<style>
.settings .input-group {
	margin-bottom: 1em;
	flex-wrap: nowrap;
}
.settings .input-group > span > input {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.settings .input-group > span:first-of-type > input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.settings .input-group > span:first-of-type {
	margin-right: -1px;
}
</style>
